<template>
  <div class="wrapper" >
    <div class="card-block">
      <h1 class="title">Sign up</h1>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-sign-in"></i>
          <InputText v-model="username" placeholder="Login" :class="{ 'p-invalid': errors.username }"/>
        </div>
        <span class="p-error">{{ errors.username }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-user"></i>
          <InputText v-model="firstname" placeholder="Name" :class="{ 'p-invalid': errors.firstname }"/>
        </div>
        <span class="p-error">{{ errors.firstname }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-envelope"></i>
          <InputText v-model="email" placeholder="E-mail" :class="{ 'p-invalid': errors.email }"/>
        </div>
        <span class="p-error">{{ errors.email }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-lock"></i>
          <Password v-model="password" placeholder="Create a password" :feedback="false" toggleMask
                    :class="{ 'p-invalid': errors.password }"/>
        </div>
        <span class="p-error">{{ errors.password }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-lock"></i>
          <Password v-model="passwordConfirm" placeholder="Confirm a password" :feedback="false" toggleMask
                    :class="{ 'p-invalid': errors.passwordConfirm }"/>
        </div>
        <span class="p-error">{{ errors.passwordConfirm }}</span>
      </div>

      <div class="flex align-items-start w-full">
        <Dropdown v-model="messengerType" :options="formatting(dataForm.messengerType)"
                  optionLabel="name" optionValue="id" class="social-select" @change="messenger=''">
          <template #value="slotProps">
            <img class="mr-1" v-if="slotProps.value == 1" src='images/icons/skype.svg' style="width: 17px; filter: invert(30%) sepia(13%) saturate(372%) hue-rotate(169deg) brightness(42%) contrast(90%)">
            <i class="pi mr-1" v-else
               :class="slotProps.value == 2? 'pi-telegram': 'pi-whatsapp'"/>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center">
              <img class="mr-1" v-if="slotProps.option.id == 1" src='images/icons/skype.svg' style="width: 17px; filter: invert(30%) sepia(13%) saturate(372%) hue-rotate(169deg) brightness(42%) contrast(90%)">
              <i class="pi mr-1" v-else
                 :class="slotProps.option.id == 2? 'pi-telegram': 'pi-whatsapp'"/>
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
        <div class="field-valid">
          <vue-tel-input v-if="messengerType == 3" class="phone-input field" v-model="messenger"
                         :validCharactersOnly="true"
                         mode="national" :enabledCountryCode="true"
                         :inputOptions="{maxlength: 16, showDialCode: true}"
                         :dropdownOptions="{ showFlags: true, showDialCodeInList:true}"></vue-tel-input>
          <InputText v-else v-model="messenger" placeholder="Your contact" :class="{ 'p-invalid': errors.messenger }"
                     class="field"/>
          <span class="p-error">{{ errors.messenger }}</span>
        </div>
      </div>
      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-briefcase"></i>
          <Dropdown class="w-full" :class="{ 'p-invalid': errors.partnerType }" v-model="partnerType"
                    :options="formatting(dataForm.partnerType)" optionLabel="name" optionValue="id"
                    placeholder="Partner type">
          </Dropdown>
        </div>
        <span class="p-error">{{ errors.partnerType }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-chart-pie"></i>
          <MultiSelect class="w-full" :class="{ 'p-invalid': errors.trafficSource }" v-model="trafficSource"
                       :options="formatting(dataForm.trafficSource)" optionLabel="name" optionValue="id"
                       placeholder="Traffic Source" display="chip" panelClass="auth-multiselect-panel"
          />
        </div>
        <span class="p-error">{{ errors.trafficSource }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-map-marker"></i>
          <MultiSelect class="w-full" :class="{ 'p-invalid': errors.geo }" v-model="geo"
                       :options="formatting(dataForm.geo)" optionLabel="name" optionValue="id"
                       placeholder="Geo" display="chip" panelClass="auth-multiselect-panel"
          />
        </div>
        <span class="p-error">{{ errors.geo }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-chart-line"></i>
          <Dropdown class="w-full" :class="{ 'p-invalid': errors.ftdPerDay }" v-model="ftdPerDay"
                    :options="formatting(dataForm.ftdPerDay)" optionLabel="name" optionValue="id"
                    placeholder="Ftd Per Day">
          </Dropdown>
        </div>
        <span class="p-error">{{ errors.ftdPerDay }}</span>
      </div>
      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-thumbs-up"></i>
          <Dropdown class="w-full" :class="{ 'p-invalid': errors.deal }" v-model="deal"
                    :options="formatting(dataForm.deal)" optionLabel="name" optionValue="id"
                    placeholder="Deal">
          </Dropdown>
        </div>
        <span class="p-error">{{ errors.deal }}</span>
      </div>

      <div class="field-valid">
        <div class="p-input-icon-left field">
          <i class="pi pi-language"></i>
          <Dropdown class="w-full" :class="{ 'p-invalid': errors.locale }" v-model="locale"
                    :options="formatting(dataForm.locale)" optionLabel="name" optionValue="id"
                    placeholder="Your Language">
          </Dropdown>
        </div>
        <span class="p-error">{{ errors.locale }}</span>
      </div>

      <vue-recaptcha v-show="captchaSettings.showRecaptcha" sitekey="6Le-XrEUAAAAAFYtocclMUsnmTZ9ZG0UGec4grAR"
                     size="normal"
                     theme="light"
                     hl="en"
                     :loading-timeout="captchaSettings.loadingTimeout"
                     @verify="recaptchaVerified"
                     @expire="recaptchaExpired"
                     ref="refCaptcha"
                     class="mb-4">
      </vue-recaptcha>
      <div class="block-btn">
        <Button label="Sign up" @click="singUp" :outlined="!checkCredits" :disabled="!checkCredits" class="auth-btn"
                :class="{'active-btn': checkCredits}"/>
      </div>
      <div class="w-full flex align-items-center">
        <Checkbox v-model="subscribe" :trueValue="1" :falseValue="0" :binary="true"/>
        <label class="ml-2 color-grey">Subscribe to news from JimPartners</label>
      </div>
    </div>
    <div class="bottom-block">
      <span>Have an account?</span>
      <router-link :to="{name: 'auth', hash: '#login'}">Log in</router-link>
    </div>
  </div>

</template>

<script setup>
import vueRecaptcha from 'vue3-recaptcha2';
import {useToast} from "primevue/usetoast";
import * as yup from 'yup';
import {useForm} from 'vee-validate';
import {computed, onMounted, ref, watch} from "vue";
import i18n from "@/locales/i18n";
import {getFormSignUpApi, signUpApi} from "@/api/auth";

import {toTypedSchema} from '@vee-validate/yup';
import {helperToken} from "@/helpers";
import {useRouter} from "vue-router";

const toast = useToast();

const router = useRouter();

const captchaSettings = {
  showRecaptcha: true,
  loadingTimeout: 30000 // 30 seconds
}

const loginRegExp = /[a-zA-Z0-9_@.*-]+$/g
// const whatsappRegExp = /^(\+)?((\d{2,3}) ?\d|\d)(([ -]?\d)|( ?(\d{2,3}) ?)){5,12}\d$/g
const passwordRegExp = /^[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/

const checkTypeMessenger = ref()

const {defineField, handleSubmit, errors} = useForm({
  validationSchema: computed(() =>
      toTypedSchema(
          yup.object({
            username: yup.string().required('Required field').matches(loginRegExp, 'Must be a valid').min(4, "Min 4").max(32, "Username should contain at most 32 characters"),
            firstname: yup.string().required('Required field').min(2, "Min 2").max(32, "Name should contain at most 32 characters").trim(),
            email: yup.string().required('Required field').email('Must be a valid'),
            password: yup.string().required('Required field').matches(passwordRegExp, 'Must be a valid').min(6, "Min 6").trim(),
            passwordConfirm: yup.string().required('Required field').oneOf([yup.ref('password')], 'Passwords must match').trim(),
            messengerType: yup.number().required('Required field'),
            messenger: checkTypeMessenger.value == 3 ? yup.string().required('Required field').min(7, "Required field").trim() : yup.string().required('Required field').trim(),
            partnerType: yup.number().required('Required field'),
            trafficSource: yup.array().required('Required field'),
            geo: yup.array().required('Required field'),
            ftdPerDay: yup.number().required('Required field'),
            deal: yup.number().required('Required field'),
            locale: yup.string().required('Required field'),
          }),
      ),
  ),
});

const [email] = defineField('email');
const [username] = defineField('username');
const [firstname] = defineField('firstname');
const [password] = defineField('password');
const [passwordConfirm] = defineField('passwordConfirm');
const [messengerType] = defineField('messengerType');
const [messenger] = defineField('messenger');
const [partnerType] = defineField('partnerType');
const [trafficSource] = defineField('trafficSource');
const [geo] = defineField('geo');
const [ftdPerDay] = defineField('ftdPerDay');
const [deal] = defineField('deal');
const [locale] = defineField('locale');

messengerType.value = 2;

const subscribe = ref(0)
const dataForm = ref({});

const captcha = ref('');
const refCaptcha = ref();


onMounted(() => {
  getForm()
})

watch(messengerType, () => {
  checkTypeMessenger.value = messengerType.value
})

watch(messenger, () => {
  if (messengerType.value == 3) {
    const regEx = /(?!^)\+|[^\w\s+]/g
    messenger.value = messenger.value.replace(regEx, '')
  }
})

const checkCredits = computed(() => {
  if (captcha.value.length) {
    return true
  } else return false
})

const local = computed(() => {
  return i18n.global.locale
})

const validForm = handleSubmit(values => {
  return values
});

function formatting(data) {
  if (data) {
    let newData = []
    for (let key in data) {
      newData.push({id: +key ? +key : key, name: data[key]})
    }
    return newData
  } else return []
}

function getForm() {
  getFormSignUpApi().then((resp) => {
    dataForm.value = resp.data.choice
  }, (error) => {
    console.log(error.response.data)
  })
}

async function singUp() {
  let form = await validForm()
  if (form) {
    form.captcha = captcha.value
    form.accept = subscribe.value
    signUpApi(form).then((resp) => {
      helperToken({token: resp.data.token})
      router.push({path: '/'})
    }, (errors) => {
      recaptchaExpired()
      for (let error in errors.response.data) {
        toast.add({severity: 'error', detail: error + ': ' + errors.response.data[error], life: 3000});
      }
    })
  }
}

function recaptchaVerified(response) {
  captcha.value = response;
}

function recaptchaExpired() {
  captcha.value = '';
  refCaptcha.value.reset();
}
</script>

<style scoped lang="scss">
@import url('/src/assets/styles/auth.scss');

.wrapper {
  height: 75%;
  position: relative;
}

.card-block {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 100px
}

.field-valid {
  margin-bottom: 4px;

  ::v-deep(.p-dropdown-label) {
    padding-left: 2.5rem;
  }

  ::v-deep(.p-multiselect-label-container) {
    padding-left: 2.5rem;
  }

  ::v-deep(.p-multiselect-label) {
    padding: 0.75rem 0.75rem 0.75rem 0;
  }

  ::v-deep(.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label) {
    padding: 0.5rem 0.5rem 0.5rem 0 !important;
  }
}


.social-select {
  height: 45px;
  margin-right: 10px;

  ::v-deep(.p-dropdown-label) {
    padding: 0.75rem 0 0.75rem 0.75rem;
  }
}

.phone-input {
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ced4da;

  &:hover {
    border-color: #3B82F6;
  }

  &:focus-within {
    box-shadow: none;
    border-color: #171FEB;
  }

  ::v-deep(.vti__input) {
    border-radius: 0 6px 6px 0;
  }

  ::v-deep(.vti__dropdown):hover {
    background-color: transparent;
  }
}

.block-btn {
  height: 58px;
  width: 100%;
  margin-bottom: 20px;

  button {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
  }
}

@media (max-height: 980px) and (max-width: 767px) {
  .wrapper {
    height: 70%;
  }
}

@media screen and (max-height: 680px) {
  .wrapper {
    height: 65%;
  }
}

@media screen and (max-height: 680px) {
  .wrapper {
    height: 65%;
  }
}

@media screen and (max-height: 650px) {
  .wrapper {
    height: 75%;
  }
}
</style>
