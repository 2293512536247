import axios from 'axios';
import {clearLocalStorage, helperToken} from "@/helpers";

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

api.interceptors.request.use((config) => {
    config.headers.Authorization =
        'Bearer ' + localStorage.getItem('crm_token');
    return config;
});

export const apiCms = axios.create({
    baseURL: process.env.VUE_APP_CMS_API_URL,
});

apiCms.interceptors.request.use((config) => {
    config.headers.Authorization = 'Bearer ' + process.env.VUE_APP_CMS_API_TOKEN;
    return config;
});



api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config
        if (originalRequest.url === '/auth/login') {
            throw error;
        }
        if (error.response.status == 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true
            try {
                const response = await refresh();
                helperToken(response.data)
                return api.request(originalRequest);
            } catch (e) {
                clearLocalStorage()
                window.history.back();
                throw error;
            }
        }
        throw error;
    }
);

export async function refresh() {
    const response = await axios.create({
        // withCredentials: true,
        baseURL: process.env.VUE_APP_API_URL,
    }).post('/auth/refresh');
    return response;
}
