<template>
  <TabsMenu v-model="typeActive"/>
  <template v-for="type in activeTypes">
    <Page :type="type" v-if="mainStore().typeProfile" v-show="typeActive == type"/>
    <Page :type="type" v-else/>
  </template>
</template>

<script>
import Page from "@/pages/Referrals/page.vue";
import TabsMenu from "@/components/tabsMenu.vue";
import {mainStore} from "@/store/main";

export default {
  name: "index",
  components: {Page, TabsMenu},
  data() {
    return {
      activeTypes: [],
      typeActive: null
    }
  },
  mounted() {
    if(mainStore().typeProfile){
      this.activeTypes.push(mainStore().typeProfile[0].id)
    } else this.activeTypes = ['']
  },
  watch:{
    typeActive(){
      if (!this.activeTypes.includes(this.typeActive)){
        this.activeTypes.push(this.typeActive)
      }
    }
  },
  computed: {},
  methods: {mainStore},
}
</script>

<style scoped lang="scss">

</style>
