<template>
  <div class="card-block">
    <h1 class="title">Log in</h1>
    <div class="p-input-icon-left field" style="margin-bottom: 24px">
      <i class="pi pi-envelope"></i>
      <InputText v-model="loginForm.email" placeholder="E-mail"/>
    </div>
    <div class="p-input-icon-left field" style="margin-bottom: 12px">
      <i class="pi pi-lock"></i>
      <Password v-model="loginForm.password" placeholder="Password" :feedback="false" toggleMask/>
    </div>
    <div class="flex justify-content-end w-full">
      <router-link :to="{name: 'auth', hash: '#reset'}" class="forgot-password">Forgot password?</router-link>
    </div>
    <vue-recaptcha v-show="captchaSettings.showRecaptcha" sitekey="6Le-XrEUAAAAAFYtocclMUsnmTZ9ZG0UGec4grAR"
                   size="normal"
                   theme="light"
                   hl="ru"
                   :loading-timeout="captchaSettings.loadingTimeout"
                   @verify="recaptchaVerified"
                   @expire="recaptchaExpired"
                   ref="vueRecaptcha"
                   class="mb-4">
    </vue-recaptcha>
    <Button label="Log in" @click="send" :outlined="!checkCredits" :disabled="!checkCredits" class="auth-btn"
            :class="{'active-btn': checkCredits}"/>
    <div class="bottom-block">
      <span>No account?</span>
      <router-link :to="{name: 'auth', hash: '#sign-up'}">Sign up</router-link>
    </div>
  </div>
</template>

<script>
import {loginApi} from "@/api/auth";
import {helperToken} from "@/helpers";
import vueRecaptcha from 'vue3-recaptcha2';


export default {
  components: {
    vueRecaptcha
  },
  data() {
    return {
      loginForm: {
        'email': '',
        'password': '',
        'captcha': '',
      },
      captchaSettings: {
        showRecaptcha: true,
        loadingTimeout: 30000 // 30 seconds
      },
    }
  },
  computed: {
    checkCredits() {
      if (this.loginForm.email.trim().length && this.loginForm.password.trim().length && this.loginForm.captcha.length) {
        return true
      }
      return false
    }
  },
  methods: {
    send() {
      if (this.checkCredits) {
        loginApi({
          "email": this.loginForm.email,
          "password": this.loginForm.password,
          "captcha": this.loginForm.captcha,
        }).then((resp) => {
          helperToken(resp.data)
          this.$router.push({path: '/'})
        }, (errors) => {
          this.recaptchaExpired()
          for (let error in errors.response.data) {
            this.$toast.add({severity: 'error', detail: error + ': ' + errors.response.data[error], life: 3000});
          }
        })
      }
    },
    recaptchaVerified(response) {
      this.loginForm.captcha = response;
    },
    recaptchaExpired() {
      this.loginForm.captcha= ''
      this.$refs.vueRecaptcha.reset();
    },
  }
}
</script>

<style scoped lang="scss">
@import url('/src/assets/styles/auth.scss');
</style>
